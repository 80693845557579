import React from "react";
import styles from "./tg-placeholder.module.scss";
import { Placeholder } from "react-bootstrap";

interface Props {
  typeClass: any;
  sizeClass?: number;
  styleClass?: string;
  loaderStyleClass?: string;
  animationVariant?: any;
  variant?: string;
  bgVariant?: string;
  numberOfLines?: number | any;
  sizeVariant?: string | any;
}

const renderPlaceholder = (props: Props) => {
  const linesArray = Array.from(
    { length: props.numberOfLines },
    (_, index) => index + 1
  );
  if (props.variant === "HTMLElement") {
    return (
      <Placeholder
        as={props.typeClass}
        className={`${props.styleClass} ${
          styles[props.loaderStyleClass ? props.loaderStyleClass : ""]
        }`}
        xs={props.sizeClass}
        animation={props.animationVariant}
        bg={props.bgVariant}
      />
    );
  } else if (props.variant === "text") {
    return linesArray.map((item: any, index: any) => (
      <Placeholder key={index}
        as={props.typeClass}
        animation={props.animationVariant}
        className={`${props.styleClass} ${
          styles[props.loaderStyleClass ? props.loaderStyleClass : ""]
        }`}
      >
        <Placeholder xs={props.sizeClass} size={props.sizeVariant} />
      </Placeholder>
    ));
  }
};

const TGPlaceholder: React.FC<Props> = (props: Props) => {
  return <>{renderPlaceholder(props)}</>;
};

export default TGPlaceholder;
