import React from "react";
import styles from "./tg-navbar.module.scss";
import { Container, Navbar } from "react-bootstrap";
import TGIcon from "../tg-icon";

interface Props {
  label: string;
  handleClick: () => void;
}

const TGNavbar = (props: Props) => {
  return (
    <Navbar className={styles.customNavbarClass}>
      <Container className={styles.customContainerClass}>
        <span onClick={props.handleClick}>
          <TGIcon icon="arrow-left" fillColor="" size="24" />
        </span>
        <span className={`${styles.customLabelClass} ms-3`}>{props.label}</span>
      </Container>
    </Navbar>
  );
};

export default TGNavbar;
