// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bYWcUSNlfzSdaDY01DIi {
  color: #ffffff;
}

.ibRxzNFQVFbuAlIBtSNE {
  background-color: #cccccc !important;
}

.uEPwzxT79MC70FhZ3VzU {
  background-color: #ececec !important;
}

.UmAE5ONHVkoxKuKz5_Fu {
  background-color: #cccccc !important;
  height: 32rem;
  border-radius: 2rem;
}

.xoymZTF7cF61lHTkTgcy {
  background-color: #ececec !important;
  height: 2.5rem !important;
  border: unset !important;
  border-radius: 1.25rem;
}

.QdvfJvMIh_efZVPtMug2 {
  width: 7rem;
}`, "",{"version":3,"sources":["webpack://./src/shared/tg-placeholder/tg-placeholder.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AACA;EACE,oCAAA;AAEF;;AAAA;EACE,oCAAA;AAGF;;AADA;EACE,oCAAA;EACA,aAAA;EACA,mBAAA;AAIF;;AAFA;EACE,oCAAA;EACA,yBAAA;EACA,wBAAA;EACA,sBAAA;AAKF;;AAHA;EACE,WAAA;AAMF","sourcesContent":[".textLoader {\n  color: #ffffff;\n}\n.imageLoader {\n  background-color: #cccccc !important;\n}\n.buttonLoader {\n  background-color: #ececec !important;\n}\n.imageContainer {\n  background-color: #cccccc !important;\n  height: 32rem;\n  border-radius: 2rem;\n}\n.buttonContainer {\n  background-color: #ececec !important;\n  height: 2.5rem !important;\n  border: unset !important;\n  border-radius: 1.25rem;\n}\n.navContainer {\n  width: 7rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textLoader": `bYWcUSNlfzSdaDY01DIi`,
	"imageLoader": `ibRxzNFQVFbuAlIBtSNE`,
	"buttonLoader": `uEPwzxT79MC70FhZ3VzU`,
	"imageContainer": `UmAE5ONHVkoxKuKz5_Fu`,
	"buttonContainer": `xoymZTF7cF61lHTkTgcy`,
	"navContainer": `QdvfJvMIh_efZVPtMug2`
};
export default ___CSS_LOADER_EXPORT___;
