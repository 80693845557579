import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import styles from "./tg-otp-input.module.scss";
import TGIcon from "../tg-icon";

export interface Props {
  label: string;
  onChange: (e: any, value: any) => void;
  type?: string;
  isError?: boolean;
  ref?: any;
  errorLabel?: any;
  placeholder?: string;
  value?: string;
  name?: string;
  customClassName?: string;
}

const TGOTPInput = (props: Props) => {
  const inputsRef = useRef<HTMLInputElement[]>([]);
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  let array = [1, 2, 3, 4];

  useEffect(() => {
      if (inputsRef.current[0]) {
        inputsRef.current[0].focus();
      }
  }, []);

  const handleChange = (e: any, index: number) => {
    const inputValue = e?.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    const newOtpValues = [...otp];
    newOtpValues[index] = numericValue;
    setOtp(newOtpValues);
    props.onChange(e, newOtpValues.join(""));
    if (numericValue && index < 3) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDownInputCode = (e: any, index: number) => {
    if (e.key === "Backspace" && !inputsRef.current[index].value && index > 0) {
      const newOtpValues = [...otp];
      newOtpValues[index - 1] = "";
      setOtp(newOtpValues);
      props.onChange(e, newOtpValues.join(""));
      inputsRef.current[index - 1].focus();
    }
  };

  const renderOTPInputs = () => {
    return array.map((x: any, index: number) => (
      <Form.Control
        styles={{ width: "25%" }}
        className={`${styles["tg-input-box"]} ${
          props.isError && styles["tg-input-error"]
        }`}
        onChange={(e) => handleChange(e, index)}
        onKeyDown={(e) => handleKeyDownInputCode(e, index)}
        type={"text"}
        name={props.name}
        ref={(el) => (inputsRef.current[index] = el!)}
        value={otp[index]}
        maxLength={1}
        inputMode="numeric"
        pattern="[0-9]"
        autoComplete="off"
      />
    ));
  };

  return (
    <>
    <Form.Group
      controlId="exampleForm.ControlInput1"
      className={styles["tg-otp-container"]}
    >
      <div className={styles["tg-input-otp-container"]}>
        {renderOTPInputs()}
      </div>
      
    </Form.Group>
    {props.isError && (
        <div
          className={`${styles["tg-input-error-label"]} ${
            styles[props.customClassName ? props.customClassName : ""]
          }`}
        >
          {props.errorLabel}
        </div>
      )}
      </>
  );
};

export default TGOTPInput;
