import React, { useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import styles from "./tg-new-datepicker.module.scss";
import TGIconLabel from "../tg-icon-label";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import * as locale from "date-fns/locale";
import TGButtonVariants from "../tg-button-variants";
import TGIcon from "../tg-icon";
import useScreenSize from "../../utils/hook/useScreenSize";
import { addDays } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { sendCalendarFareData } from "../../OSCI/src/slice/calendarPricingSlice";
import { RootState } from "../../OSCI/src/slice/RootReducer";

export interface Props {
  label?: string;
  customClassName?: string;
  onChange?: (e: any, label: string) => void;
  type?: string;
  isError?: boolean;
  errorLabel?: string;
  listData?: any;
  headerLabel?: string;
  searchPlaceHolder?: string;
  dateData: any;
  setDateData: React.Dispatch<React.SetStateAction<any>>;
  onReset?: (label: string) => void;
  focused: any;
  setFocused: Function;
  minDate?: Date | any;
  isRedemption?: boolean;
  name?: string;
  onDateBlur?: any;
  onEnter?: () => {};
  componentName?: string;
  hideFare?: boolean;
}

const TGDateCalendar = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dateData, setDateData } = props;
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [key, setKey] = useState(0);
  const { i18n } = useTranslation();
  let calendarRef: any = useRef(null);
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 364);
  const currentRef = useRef<any>(null);
  const currentLanguage: any = i18n?.language?.split("-")[1];
  const calendarFares = useSelector(
    (state: RootState) => state?.osciReducer?.dateFare.prices
  );
  const loadingFare = useSelector(
    (state: RootState) => state?.osciReducer?.dateFare.isLoading
  );

  const moment = require("moment");
  const bookingWidgetData: any = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget
  );

  useEffect(() => {
    let calenderCount = localStorage.getItem("calenderCount");
    if (props.focused.date) {
      if (!calenderCount) {
        calendarRef.current.click();
        localStorage.setItem("calenderCount", "1");
      } else {
        localStorage.removeItem("calenderCount");
      }
    } else {
      localStorage.removeItem("calenderCount");
    }
  }, [props.focused.date]);

  const vnDay = [
    "Mặt trời",
    "Thứ hai",
    "thứ ba",
    "Thứ Tư",
    "Thứ năm",
    "Thứ sáu",
    "Đã ngồi",
  ];

  const vnMonth = [
    "Tháng Một",
    "Tháng Hai",
    "Tháng Ba",
    "Tháng Tư",
    "Tháng Năm",
    "Tháng Sáu",
    "Tháng Bảy",
    "Tháng Tám",
    "Tháng Chín",
    "Tháng Mười",
    "Tháng Mười Một",
    "Tháng Mười Hai",
  ];

  const ruDay = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

  const ruMonth = [
    "январь",
    "февраль",
    "Маршировать",
    "апрель",
    "Может",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "ноябрь",
    "Декабрь",
  ];

  const vnLocale: any = {
    localize: {
      day: (n: any) => vnDay[n],
      month: (n: any) => vnMonth[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };

  const ruLocale: any = {
    localize: {
      day: (n: any) => ruDay[n],
      month: (n: any) => ruMonth[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };

  const filterLocale = (code: string) => {
    const splittedCode = code.split("-")
    switch (code) {
      case "ar-dz":
      case "ar-eg":
      case "ar-ma":
      case "ar-sa":
      case "ar-tn":
      case "de-at":
      case "en-au":
      case "en-ca":
      case "en-gb":
      case "en-ie":
      case "en-in":
      case "en-nz":
      case "en-us":
      case "en-za":
      case "fa-ir":
      case "fr-ca":
      case "fr-ch":
      case "it-ch":
      case "nl-be":
      case "pt-br":
      case "zh-cn":
      case "zh-hk":
      case "zh-tw":
        return `${splittedCode?.[0]}${splittedCode?.[1]?.toUpperCase()}`;
      case "vn-VN":
        return vnLocale;
      case "ru-RU":
        return ruLocale;
      case "tw-HK":
        return "zhHK";
      default:
        return code.split("-")[0];
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.setProperty("--carousel-z-index", "-1");
      // document.documentElement.style.setProperty("--everymundo-z-index", "-1");
      setStartDate(dateData?.stDate);
      setEndDate(dateData?.edDate);
      handleFocus();
    }
  }, [isOpen]);

  const handleOutsideClick = (e: any) => {
    if (currentRef.current && !currentRef.current.contains(e?.target as Node)) {
      setIsOpen(false);
      // document.documentElement.style.setProperty("--html-position", "");
      document.documentElement.style.setProperty("--html-width", "");
      document.documentElement.style.setProperty("--inspirationdealzindex", "");
      // document.documentElement.style.setProperty(
      //   "--everymundo-z-index",
      //   "unset"
      // );
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  const [farePriceForDate, setFarePriceForDate] = useState(0);
  const [fareCurrency, setFareCurrency] = useState("");
  const [currentActiveMonth, setCurrentActiveMonth] = useState("");
  const [lastRequest, setLastRequest] = useState({
    origin: "",
    destination: "",
    classes: [],
  });

  const convertAndFormatFarePrice = (value: string | number) => {
    if (typeof value === "string") {
      value = value.trim();

      if (value.endsWith("K")) {
        let numericValue = parseFloat(value.slice(0, -1)) * 1000;
        return numericValue.toLocaleString();
      } else if (value.endsWith("M")) {
        let numericValue = parseFloat(value.slice(0, -1)) * 1000000;
        return numericValue.toLocaleString();
      }
    }
    return typeof value === "number" ? value.toLocaleString() : value;
  };

  const formattedFarePrice = convertAndFormatFarePrice(farePriceForDate);

  useEffect(() => {
    if (typeof locale !== "undefined") {
      Object.keys(locale)?.forEach((x: string) => {
        const y: any = locale?.[filterLocale(x) as keyof typeof locale];
        registerLocale(filterLocale(x), y);
      });
    }
  }, [filterLocale]);

  useEffect(() => {
    if (isOpen) {
      if (startDate && endDate) {
        let fareForDate: any = calendarFares?.find(
          (dt: any) => dt.date === moment(endDate).format("YYYY-MM-DD")
        );
        console.log(fareForDate, endDate, calendarFares, "FAREs");
        setFarePriceForDate(fareForDate?.fare?.totalPrice);
        setFareCurrency(fareForDate?.fare?.currencyCode);
      } else {
        setFareCurrency("");
        setFarePriceForDate(0);
      }
    }
  }, [isOpen, calendarFares]);

  const handleChange = (date: any, label: string, isRT: boolean = false) => {
    let calendarData = {};
    if (props.type === "2way") {
      const [start, end] = date;
      setStartDate(start);
      setEndDate(end);

      let endDate = moment(end).format("YYYY-MM-DD");

      let roundTripStDate = moment(start).format("YYYY-MM-DD");
      let today = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 12,
        0
      );
      let roundTripEdDate = moment(today).format("YYYY-MM-DD");

      let travelClass =
        bookingWidgetData?.fieldData?.class === "Business & First"
          ? "BUSINESS"
          : "ECONOMY";

      calendarData = {
        journeyType: props.type === "2way" ? "ROUND_TRIP" : "ONE_WAY",
        origin: bookingWidgetData?.fieldData?.from?.airportCode,
        destination: bookingWidgetData?.fieldData?.to?.airportCode,
        classes: [travelClass],
        dateRange: {
          startDate: roundTripStDate,
          endDate: roundTripEdDate,
        },
        histogramType: "INBOUND",
      };
      if (!props?.hideFare) {
        dispatch(sendCalendarFareData(calendarData));
      }
      // generateFareData();
      let fareForDate: any = calendarFares?.find(
        (dt: any) => dt.date === endDate
      );
      setFarePriceForDate(fareForDate?.fare?.totalPrice);
      setFareCurrency(fareForDate?.fare?.currencyCode);
    } else {
      setStartDate(date);
      let end = moment(date).format("YYYY-MM-DD");
      let fareForDate: any = calendarFares?.find((dt: any) => dt.date === end);
      setFarePriceForDate(fareForDate?.fare?.totalPrice);
      setFareCurrency(fareForDate?.fare?.currencyCode);
    }
  };

  const handleNextMonth = (date: any) => {
    if (!props?.hideFare) {
      setCurrentActiveMonth(
        moment(
          document.getElementsByClassName(
            `react-datepicker__current-month--hasMonthYearDropdown`
          )?.[0]?.innerHTML
        ).format("YYYY-MM")
      );
    }
  };

  const isValidPayload = (payload: { origin: any; destination: any }) => {
    return payload.origin && payload.destination;
  };

  const hasChanged = (currentRequest: {
    origin: any;
    destination: any;
    classes: any;
  }) => {
    return (
      currentRequest.origin !== lastRequest.origin ||
      currentRequest.destination !== lastRequest.destination ||
      JSON.stringify(currentRequest.classes) !==
        JSON.stringify(lastRequest.classes)
    );
  };

  const handleFocus = () => {
    let date = moment().format("YYYY-MM-DD");
    const today = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 12,
      0
    );
    let end = moment(today).format("YYYY-MM-DD");
    if (props?.dateData?.stDate) {
      date = moment(props?.dateData?.stDate)
        .startOf("month")
        .format("YYYY-MM-DD");
      end = moment(date).add(1, "months").endOf("month").format("YYYY-MM-DD");
    }
    setCurrentActiveMonth(moment(date).format("YYYY-MM"));
    let travelClass =
      bookingWidgetData?.fieldData?.class === "Business & First"
        ? "BUSINESS"
        : "ECONOMY";

    const calendarData = {
      journeyType: props.type === "2way" ? "ROUND_TRIP" : "ONE_WAY",
      origin: bookingWidgetData?.fieldData?.from?.airportCode,
      destination: bookingWidgetData?.fieldData?.to?.airportCode,
      classes: [travelClass],
      dateRange: {
        startDate: date,
        endDate: end,
      },
      histogramType: "",
    };
    if (
      isValidPayload(calendarData) &&
      hasChanged(calendarData) &&
      !props?.hideFare
    ) {
      dispatch(sendCalendarFareData(calendarData));
      setLastRequest({
        origin: calendarData.origin,
        destination: calendarData.destination,
        classes: calendarData.classes,
      });
    }
  };

  const handleContinue = (e: any) => {
    e.preventDefault();
    let data = [];
    if (props.type === "1way") {
      data = [new Date(startDate)];
    } else {
      data = [new Date(startDate), new Date(endDate)];
    }
    if (props.onChange) {
      props.onChange(data, props.label ? props.label : "calender");
    }
    setDateData({ ...dateData, stDate: startDate, edDate: endDate });
    setIsOpen(false);
    // document.documentElement.style.setProperty("--html-width", "");
    document.documentElement.style.setProperty("--inspirationdealzindex", "");
    props.setFocused({ from: false, to: false, date: false, pax: true });
  };

  const handleReset = (label: string) => {
    setStartDate(new Date());
    setTimeout(() => {
      setStartDate(null);
    }, 10);
    setEndDate(null);
    setFarePriceForDate(0);
    setFareCurrency("");
    setKey((state) => state + 1);
    if (props.onReset) {
      props.onReset(label);
    }

    let date = moment().format("YYYY-MM-DD");
    const today = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 12,
      0
    );
    let end = moment(today).format("YYYY-MM-DD");
    if (props?.dateData?.stDate) {
      date = moment(props?.dateData?.stDate)
        .startOf("month")
        .format("YYYY-MM-DD");
      end = moment(date).add(1, "months").endOf("month").format("YYYY-MM-DD");
    }
    setCurrentActiveMonth(moment(date).format("YYYY-MM"));
    let travelClass =
      bookingWidgetData?.fieldData?.class === "Business & First"
        ? "BUSINESS"
        : "ECONOMY";

    const calendarData = {
      journeyType: props.type === "2way" ? "ROUND_TRIP" : "ONE_WAY",
      origin: bookingWidgetData?.fieldData?.from?.airportCode,
      destination: bookingWidgetData?.fieldData?.to?.airportCode,
      classes: [travelClass],
      dateRange: {
        startDate: date,
        endDate: end,
      },
      histogramType: "",
    };

    if (!props?.hideFare) {
      dispatch(sendCalendarFareData(calendarData));
    }
  };

  function findMinMax(monthPrice: any) {
    let datas: any = [];
    monthPrice?.reduce(function (results: any, node: any) {
      if (node?.fare?.totalPrice !== "-") {
        datas.push(getAbsPrice(node.fare["totalPrice"]));
      }
    });

    return {
      min: Math.min(...datas),
    };
  }

  function getAbsPrice(priceVal: any) {
    // Remove the currency symbol if it exists
    let cleanedPrice = priceVal.replace(/[,€£$]/g, "").trim();

    let multfactor =
      cleanedPrice.indexOf("K") !== -1
        ? 1000
        : cleanedPrice.indexOf("M") !== -1
        ? 1000000
        : 1;

    return parseFloat(cleanedPrice) * multfactor;
  }

  const newFares = calendarFares?.reduce((groups, fareInfo: any) => {
    const date = moment(fareInfo?.date).format("YYYY-MM");
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(fareInfo);
    return groups;
  }, {} as Record<string, any[]>);

  const minFareByMonth: any = {};
  newFares &&
    Object.keys(newFares).forEach((x: any) => {
      minFareByMonth[x] = findMinMax(newFares?.[x]);
    });

  const renderDayContents = (day: any, date: any) => {
    const getFareForDate = (date: Date, newFares: any): string | null => {
      const dateyrmonthformat = moment(date).format("YYYY-MM");
      const currentMonthPrice = newFares[dateyrmonthformat];
      const dateStr = moment(date).format("YYYY-MM-DD");

      if (
        !currentMonthPrice ||
        !Array.isArray(currentMonthPrice) ||
        currentMonthPrice.length === 0
      )
        return "-";

      const fareItem: any = currentMonthPrice?.find(
        (item) => item.date === dateStr
      );

      let minFarePrice: any = 0;
      const currentMonth: any = document
        .getElementsByClassName(`react-datepicker__month`)?.[0]
        ?.ariaLabel?.split("  ")[1];

      const nextMonth: any = document
        .getElementsByClassName(`react-datepicker__month`)?.[1]
        ?.ariaLabel?.split("  ")[1];

      if (isDesktopView) {
        if (minFareByMonth[currentMonth] && minFareByMonth[nextMonth]) {
          minFarePrice = Math.min(
            minFareByMonth[currentMonth]?.min,
            minFareByMonth[nextMonth]?.min
          );
        } else if (!minFareByMonth[currentMonth]?.min) {
          minFarePrice = minFareByMonth[nextMonth]?.min;
        } else {
          minFarePrice = minFareByMonth[currentMonth]?.min;
        }
      } else {
        minFarePrice = minFareByMonth[currentMonth]?.min;
      }

      return fareItem &&
        (moment(date)?.isBefore(startDate) ||
          moment(date)?.isAfter(moment(endDate)?.subtract(1, "days")) ||
          !startDate ||
          !endDate) ? (
        <div
          className={
            fareItem?.fare?.totalPrice !== "-" &&
            ((!startDate &&
              !endDate &&
              moment(date)?.format("YYYY-MM-DD") ===
                moment(new Date())?.format("YYYY-MM-DD")) ||
              (moment(date)?.format("YYYY-MM-DD") !==
                moment(endDate)?.format("YYYY-MM-DD") &&
                moment(date)?.format("YYYY-MM-DD") !==
                  moment(startDate)?.format("YYYY-MM-DD"))) &&
            ((!isDesktopView &&
              getAbsPrice(fareItem?.fare?.totalPrice) ===
                parseFloat(
                  minFareByMonth[moment(date)?.format("YYYY-MM")]?.min
                )) ||
              (isDesktopView &&
                getAbsPrice(fareItem?.fare?.totalPrice) ===
                  parseFloat(minFarePrice))) &&
            styles["minimum-fare"]
          }
        >
          {fareItem?.fare?.totalPrice}
        </div>
      ) : (
        "-"
      );
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const maxDate = new Date(today);
    maxDate.setDate(today.getDate() + 360);
    let farePrice = "-";

    if (!calendarFares || calendarFares?.length == 0) {
      farePrice = "-";
    } else if (date >= today && date <= maxDate && calendarFares?.length) {
      farePrice = getFareForDate(date, newFares) || "-";
    }

    return (
      <div
        className={`${
          (props.name === "multicity" || props?.hideFare) &&
          styles["tg-calendar-date-value-multicity"]
        } d-flex flex-column ${styles["tg-calendar-date-value-multicities"]}`}
      >
        <div className={styles["tg-calendar-date-value"]}>{day}</div>
        {!props?.hideFare &&
          (loadingFare ? (
            <div className={styles.placeholder}>
              <div className={styles.animatedPlaceholder}></div>
            </div>
          ) : (
            <div className={`tg-calendar-price`}>{farePrice}</div>
          ))}
      </div>
    );
  };

  const formatWeek = (day: string) => {
    const currentLng = i18n.language?.split("-");
    switch (currentLng[0]) {
      case "ar":
      case "ru":
        return day;
      case "de":
      case "ja":
        return day.substr(0, 2);
      default:
        return day.substr(0, 3);
    }
  };

  const renderDatePicker = () => {
    return (
      <ReactDatePicker
        key={key}
        open={isOpen}
        selected={startDate}
        ref={(c) => (calendarRef = c)}
        monthsShown={isDesktopView ? 2 : new Date().getDate() === 1 ? 12 : 13}
        selectsRange={props.type !== "1way"}
        startDate={startDate}
        endDate={endDate}
        locale={filterLocale(i18n.language)}
        minDate={props.minDate || new Date()}
        maxDate={
          props?.isRedemption
            ? addDays(props?.minDate, 2)
            : props.name === "multicity"
            ? addDays(new Date(), 360)
            : addDays(props.minDate || new Date(), 360)
        }
        onChange={(date) =>
          handleChange(date, props.label ? props.label : "calendar", true)
        }
        showMonthYearDropdown={isDesktopView}
        calendarClassName={styles["tg-calendar-custom-datepicker-wrapper"]}
        dayClassName={() => styles["tg-datepicker-day"]}
        weekDayClassName={() => styles["tg-datepicker-weekday"]}
        monthClassName={() => styles["tg-datepicker-month"]}
        wrapperClassName={styles["tg-datepicker-custom-wrapper"]}
        renderDayContents={(day, dates) => renderDayContents(day, dates)}
        shouldCloseOnSelect={false}
        inline={true}
        forceShowMonthNavigation={true}
        formatWeekDay={(nameOfDay) => formatWeek(nameOfDay)}
        calendarStartDay={0}
        peekNextMonth={false}
        onClickOutside={(e) => {
          if (
            currentRef.current &&
            !currentRef.current.contains(e?.target as Node)
          ) {
            isDesktopView &&
              setTimeout(() => {
                // setIsOpen(false);
                props.setFocused({
                  from: false,
                  to: false,
                  date: false,
                  pax: false,
                });
                props.onDateBlur &&
                  props.onDateBlur({
                    from: false,
                    to: false,
                    date: false,
                  });
              }, 500);
          }
        }}
        showDisabledMonthNavigation
        openToDate={!isDesktopView ? new Date() : null}
        onMonthChange={(date) => handleNextMonth(date)}
      />
    );
  };

  const renderMobileComponent = () => {
    return (
      <Offcanvas
        show={isOpen}
        onHide={() => {
          setIsOpen(false);
          // document.documentElement.style.setProperty("--html-position", "");
          document.documentElement.style.setProperty("--html-width", "");
          document.documentElement.style.setProperty(
            "--inspirationdealzindex",
            ""
          );
          props.setFocused({ from: false, to: false, date: false, pax: false });
        }}
        placement="bottom"
        className={styles["tg-datepicker-offcanvas"]}
        onEntered={() =>
          props.onEnter
            ? props?.onEnter()
            : document
                .querySelectorAll(
                  ".react-datepicker__day--selected:not(.react-datepicker__day--outside-month)"
                )[0]
                ?.parentNode?.parentNode?.parentNode?.scrollIntoView({
                  block: "start",
                })
        }
      >
        <Offcanvas.Header
          closeButton
          className={styles["tg-datepicker-offcanvas-header"]}
        >
          <Offcanvas.Title className={styles["tg-datepicker-offcanvas-title"]}>
            {t("label_calendar_header")}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <div style={{ padding: "12px" }}>
          <div className="d-flex gap-2">
            <div className={styles["tg-datepicker-input-wrapper"]}>
              <input
                type="text"
                id="depart"
                autoComplete="off"
                required
                className={styles["tg-datepicker-input"]}
                value={startDate ? moment(startDate).format("DD-MMM-YYYY") : ""}
                readOnly
              />
              <label
                htmlFor="depart"
                className={styles["tg-datepicker-input-label"]}
              >
                {t("label_book_widget_depart")}
              </label>
            </div>
            {props.type !== "1way" && (
              <div className={styles["tg-datepicker-input-wrapper"]}>
                <input
                  type="text"
                  id="return"
                  autoComplete="off"
                  required
                  className={styles["tg-datepicker-input"]}
                  value={endDate ? moment(endDate).format("DD-MMM-YYYY") : ""}
                  readOnly
                />
                <label
                  htmlFor="return"
                  className={styles["tg-datepicker-input-label"]}
                >
                  {t("label_book_widget_return")}
                </label>
              </div>
            )}
          </div>
          <div
            className={styles["tg-datepicker-reset-link"]}
            onClick={() => handleReset(props.label ? props.label : "calendar")}
          >
            <TGIconLabel
              label=" Reset"
              position="left"
              icon="reset-icon"
              size="16"
            />
          </div>
        </div>
        <Offcanvas.Body
          className={`${styles["tg-datepicker-body"]} date-picker-body`}
        >
          {renderDatePicker()}
          <div className={styles["tg-datepicker-confirm-button"]}>
            {!props?.hideFare && (
              <div>
                <span className={styles.approxFare}>
                  {t("label_approx_price")}
                  <span className={styles.fareDate}>
                    {fareCurrency} {formattedFarePrice}
                  </span>
                </span>
                <span className={styles.cheapestFares}>
                  <TGIcon
                    icon={"cheapest-fare"}
                    size="24px"
                    fillColor=""
                  ></TGIcon>
                  <span className={styles.fareText}>{t("label_cheapest")}</span>
                </span>
              </div>
            )}
            <TGButtonVariants
              label={t("button_confirm")}
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="30px"
              boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
              bgColor="#684B9B"
              padding="16px"
              textColor="#FFFFFF"
              fontFamily="Inter"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="160%"
              borderDisabled="0.791px solid #E3D4FE"
              bgColorDisabled="#E3D4FE"
              boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
              bgColorHover="#684b9b"
              borderHover="0.791px solid #E3D4FE"
              boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
              width="100%"
              height="50px"
              onClick={handleContinue}
              disabled={
                props.type === "2way" ? !startDate || !endDate : !startDate
              }
            />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    );
  };

  const renderDesktopComponent = () => {
    return (
      isOpen && (
        <div
          className={`${styles["tg-datepicker-popup"]} booking-widget-overlay`}
          ref={currentRef}
          style={{
            marginTop: `${
              props.componentName === "FlightSchedule" ? "5rem" : "16px"
            }`,
          }}
        >
          <div
            className={styles["tg-datepicker-reset-link-desktop"]}
            onClick={() => handleReset(props.label ? props.label : "calendar")}
          >
            <TGIconLabel
              label=" Reset"
              position="left"
              icon="reset-icon"
              size="16"
              onClick={() =>
                handleReset(props.label ? props.label : "calendar")
              }
            />
          </div>
          {renderDatePicker()}
          <div className={styles.flexContainer}>
            {!props?.hideFare && (
              <div className={styles.approxPriceCont}>
                <span className={styles.approxFare}>
                  {t("label_approx_price")}
                  <span className={styles.fareDate}>
                    {fareCurrency} {formattedFarePrice}
                  </span>
                </span>
                <span className={styles.cheapestFares}>
                  <TGIcon
                    icon={"cheapest-fare"}
                    size="24px"
                    fillColor=""
                  ></TGIcon>
                  <span className={styles.fareText}>{t("label_cheapest")}</span>
                </span>
              </div>
            )}
            <div>
              <TGButtonVariants
                label={"Confirm"}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="30px"
                boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                bgColor="#684B9B"
                padding="16px"
                textColor="#FFFFFF"
                fontFamily="Inter"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="160%"
                borderDisabled="0.791px solid #E3D4FE"
                bgColorDisabled="#E3D4FE"
                boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                bgColorHover="#684b9b"
                borderHover="0.791px solid #E3D4FE"
                boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                width={isDesktopView ? "25%" : "100%"}
                height="50px"
                onClick={handleContinue}
                disabled={
                  props.type === "2way" ? !startDate || !endDate : !startDate
                }
              />
            </div>
          </div>
        </div>
      )
    );
  };

  return (
    <>
      <div
        className={`${styles["tg-date-picker-container"]} ${
          isOpen && styles["tg-date-picker-container-focused"]
        }  ${props.type === "2way" && styles["tg-date-picker-alignment"]}`}
        ref={calendarRef}
        onClick={(e: any) => {
          setIsOpen(true);
          if (!isDesktopView) {
            // document.documentElement.style.setProperty(
            //   "--html-position",
            //   "fixed"
            // );
            document.documentElement.style.setProperty("--html-width", "100%");
          }
          if (isDesktopView) {
            // e?.target?.scrollIntoViewIfNeeded({block: "center"})
            document.documentElement.style.setProperty(
              "--inspirationdealzindex",
              "-1"
            );
          }
        }}
        title={props.label}
      >
        <div>
          {!props.dateData?.stDate ? (
            <p
              className={`${styles["tg-datepicker-label"]} ${
                isOpen && styles["tg-datepicker-label-focused"]
              }  ${currentLanguage === "ru" ? styles["tg-font-size"] : ""} `}
            >
              {t("label_book_widget_depart")}
            </p>
          ) : (
            <div className={styles["tg-datpicker-label-container"]}>
              <div
                className={`${styles["tg-datepicker-label-focused"]} ${
                  currentLanguage === "ru" ? styles["tg-font-size"] : ""
                }`}
              >
                {t("label_book_widget_depart")}
              </div>
              <div
                className={
                  props.type === "1way"
                    ? styles["tg-datepicker-input-value"]
                    : styles["tg-datepicker-input-value-return"]
                }
              >
                {moment(props?.dateData?.stDate).format("D MMM YYYY")}
              </div>
            </div>
          )}
        </div>
        {props.type !== "1way" && (
          <>
            <div className={styles["tg-symbol-alignment"]}></div>
            <div className={styles.tgSymbol}>
              {!props.dateData?.edDate ? (
                <p
                  className={`${styles["tg-datepicker-label"]} ${
                    isOpen && styles["tg-datepicker-label-focused"]
                  } ${currentLanguage === "ru" ? styles["tg-font-size"] : ""}`}
                >
                  {t("label_book_widget_return")}
                </p>
              ) : (
                <div className={styles["tg-datpicker-label-container"]}>
                  <div
                    className={`${styles["tg-datepicker-label-focused"]} ${
                      currentLanguage === "ru" ? styles["tg-font-size"] : ""
                    }`}
                  >
                    {t("label_book_widget_return")}
                  </div>
                  <div className={styles["tg-datepicker-input-value-return"]}>
                    {moment(props?.dateData?.edDate).format("D MMM YYYY")}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        <div className={styles["calender-icon"]} role="button" aria-label = "calender icon" tabIndex={0}>
          <TGIcon icon="calendar-icon" fillColor="" size="18" />
        </div>
      </div>
      {isDesktopView && isOpen && (
        <div className={styles.desktopStyle}>{renderDesktopComponent()}</div>
      )}
      {!isDesktopView && renderMobileComponent()}
    </>
  );
};

export default TGDateCalendar;
