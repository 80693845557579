// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nO1plWKR3qj8WtHT_vQv {
  width: 100%;
  height: 8px;
  --bs-progress-bar-color: #ddd9df;
  --bs-progress-bar-bg: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/shared/tg-progressbar/tg-progressbar.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,WAAA;EACA,gCAAA;EACA,6BAAA;AACJ","sourcesContent":[".tg-progress-bar {\n    width: 100%;\n    height: 8px;\n    --bs-progress-bar-color: #ddd9df;\n    --bs-progress-bar-bg: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tg-progress-bar": `nO1plWKR3qj8WtHT_vQv`
};
export default ___CSS_LOADER_EXPORT___;
