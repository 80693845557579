import Form from "react-bootstrap/Form";
import styles from "./tg-radio.module.scss";
import { RadioProps } from "../../models/models";
import { useState } from "react";

export const TGRadio: React.FC<RadioProps> = ({
  selected,
  onChange,
  options,
  isBorder,
  name,
  align="row",
  isFullWidth
}) => {
  const [currentSelected, setCurrentSelected] = useState(selected || options?.[0]?.id)

  const handleRadioChange = (opt: any) => {
    setCurrentSelected(opt?.id)
    onChange(opt);
  };
  return (
    <div className={`${styles[`tg-radio-main-wrapper-${align}`]} tgRadio`}>
        {options?.map((option) => {
          return (
            <span onClick={() => handleRadioChange(option)} key={option?.id} className={styles[`tg-radio-inner-wrapper`]}>
            <Form.Check
              key={option?.id}
              type="radio"
              id={`radio-${name + '-' + option.id}`}
              label={option?.label}
              checked={option?.id === currentSelected}
              className={`${styles["tg-radio-option-wrapper"]} ${isBorder && styles["tg-radio-border-option-wrapper"]} ${option.id === currentSelected && isBorder && styles[`tg-radio-option-wrapper-selected`]} ${isFullWidth && styles["tg-radio-width"]}`}
              onChange={() => handleRadioChange(option)}
            />
            </span>
          );
        })}
    </div>
  );
};
