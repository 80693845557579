import React, {useEffect} from "react";
import { Badge, Button } from "react-bootstrap";
import styles from "./tg-pills.module.scss";
import TGIcon from "../tg-icon";

interface Props {
  variant: string;
  color: string;
  bg: string;
  label: string;
  customClass?: string;
  iconName?: string;
  fillColor?: string;
  size?: string
  align?: string;
  border?: string;
}

const TGPills = (props: Props) => {

 useEffect(()=> {
    if(props.color){
        document.documentElement.style.setProperty("--pill-color", `${props.color}`)
    }
    if(props.bg){
        document.documentElement.style.setProperty("--pill-bg", `${props.bg}`)
    }
    if(props.border){
      document.documentElement.style.setProperty("--pill-border", `${props.border}`)
    }
 }, [props.color, props.bg])

  return (
    <Badge pill bg="primary" className={`${styles[props.variant]} ${styles[props.customClass ? props.customClass : '']}`}>
        <span className={styles[`inner-content-${props.align}`]}>
            {props.iconName && <TGIcon icon ={props.iconName} fillColor={props.fillColor || ''} size={props.size || '24px'} />}
            {' '}{props.label}
        </span>
    </Badge>
  );
};

export default TGPills;
