import React from "react";
import { ProgressBar } from "react-bootstrap";
import { ProgressBarProps } from "../../OSCI/src/models/models";
import styles from "./tg-progressbar.module.scss"

const TGProgressBar: React.FC<ProgressBarProps> = ({
  customProgressBarClass,
  currentValue,
}) => {
  return <ProgressBar className={`${customProgressBarClass} ${styles["tg-progress-bar"]}`} now={currentValue} />;
};

export default TGProgressBar;