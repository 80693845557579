import React from 'react';
import NewStyles from './news-card.module.scss';
import { NewsList } from '../../OSCI/src/models/models';
import { Link } from 'react-router-dom';
import TGButtonVariants from "../../shared/tg-button-variants";
interface TGNewCardProps {
  data?: any;
}
export const TGNewsCard: React.FC<TGNewCardProps> = ({ data }) => {
  const handleCardClick = (link: string) => {
    window.open(link);
  }
  return (
    <Link style={{ textDecoration: 'none' }} to={data[0]?.redirectionLink} target="_blank">
      <div className={NewStyles.contentContainer}>
        <div className={NewStyles.headingContainer}>
          <span className={NewStyles.heading}>
            {data[0]?.heading}</span>
          <span className={NewStyles.description}>
            {data[0]?.description}
          </span>
          <span className={NewStyles.date}>{data[0]?.date}</span>
        </div>
        <div className={NewStyles.newsButtonContainer}>
          <button className={NewStyles.readMoreButton} onClick={() => handleCardClick(data[0]?.redirectionLink)} >{data[0]?.buttonText}</button>
        </div>
        {data[1] % 2 == 0 ? <div className={NewStyles.border}></div> : ''}
      </div>
    </Link>
  )
}