import React, { useState, useRef, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";

import styles from "./tg-search.module.scss";
import TGButtonVariants from "../tg-button-variants";
import TGIcon from "../tg-icon";
import { useTranslation } from "react-i18next";

interface Props {
  placeholderText: string;
  searchURL: string;
  isDesktopView: boolean;
}

const TGSearch: React.FC<Props> = ({
  placeholderText,
  searchURL,
  isDesktopView,
}) => {
  const [focused, setFocused] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showClearIcon, setShowClearIcon] = useState(false);
  const inputRef: any = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      setFocused(true);
    }
  }, [inputRef.current]);

  const handleInputChange = () => {
    const inputValue = inputRef.current.value;
    setIsButtonDisabled(inputValue === "");
    if (inputValue) {
      setShowClearIcon(true);
    } else {
      setShowClearIcon(false);
    }
  };

  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement) {
      inputRef.current.focus();
      setFocused(true);
      inputElement.addEventListener("input", handleInputChange);
      inputElement.addEventListener("focus", handleFocus);
      inputElement.addEventListener("blur", handleBlur);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener("input", handleInputChange);
        inputElement.removeEventListener("focus", handleFocus);
        inputElement.removeEventListener("blur", handleBlur);
      }
    };
  }, []);

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchQuery(e.target.value);
    }
  };

  const handleClickSearchIcon = () => {
    const searchParam = inputRef.current.value;
    searchQuery(searchParam);
  };

  const handleClear = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setShowClearIcon(false);
      setIsButtonDisabled(inputRef.current.value === "");
    }
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const searchQuery = (params: string) => {
    window.location.href = params ? searchURL + params : searchURL;
  };

  return (
    <div className={styles.searchOuterContainer}>
      <InputGroup className={styles.searchContainer}>
        <InputGroup.Text
          id="search-icon"
          onClick={handleClickSearchIcon}
          className={focused ? styles.searchIconFocus : ""}
        >
          <TGIcon
            icon="search-icon"
            size={isDesktopView ? "24" : "20"}
            fillColor=""
          />
        </InputGroup.Text>
        <Form.Control
          placeholder={placeholderText}
          aria-label={placeholderText.replace(/\.*$/, "").trim().toLowerCase()}
          aria-describedby="search-icon"
          onKeyDown={handleKeyDown}
          ref={inputRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleInputChange}
          type="search"
          autoFocus={true}
        />
        <InputGroup.Text
          id="search-icon"
          onClick={handleClear}
          className={`${styles.clearIcon} ${
            focused ? styles.closeIconFocus : ""
          }`}
        >
          {showClearIcon && (
            <TGIcon
              icon="close-icon"
              size={isDesktopView ? "24" : "20"}
              fillColor=""
            />
          )}
        </InputGroup.Text>
      </InputGroup>
      {isDesktopView && (
        <TGButtonVariants
          label={t("button_flight_status")}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="30px"
          boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
          bgColor="#684B9B"
          bgColorHover="#381B6B"
          padding="8px 24px"
          textColor="#FFFFFF"
          fontFamily="Inter"
          fontSize="18px"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="160%"
          height="61px"
          width="170px"
          onClick={handleClickSearchIcon}
          bgColorDisabled="#E3D4FE"
          boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
          disabled={isButtonDisabled}
        />
      )}
    </div>
  );
};

export default TGSearch;
