// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --pill-bg: "#F5EFFF";
  --pill-color: "#684B9B";
  --pill-border: "unset";
}

.JSkafoDc_dHOYusifOxV {
  display: flex;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: var(--pill-bg) !important;
  color: var(--pill-color) !important;
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 19.2px;
  border: var(--pill-border) !important;
}

.WH8ElQUbEFBhlCYvARpY {
  display: flex;
  flex-direction: row-reverse;
}

.gtIPJiE1rwlGjfPERjjX {
  padding: var(--Spacing--12px, 12px) 20px !important;
  align-items: flex-start !important;
  gap: 10px;
  border-radius: 100px !important;
  background: var(--gray-surface, #F2F2FA) !important;
  color: var(--gray-text, #6A6D87);
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 150% !important;
  /* 24px */
}

.vXCNoFr2qaDnmcJheGAH {
  width: 50px !important;
  height: 19px !important;
  place-self: center;
}
.vXCNoFr2qaDnmcJheGAH > span {
  color: var(--primary-text, #684B9B);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}`, "",{"version":3,"sources":["webpack://./src/shared/tg-pills/tg-pills.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,uBAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,qCAAA;EACA,mCAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,qCAAA;AACF;;AAEA;EACE,aAAA;EACA,2BAAA;AACF;;AAEA;EACE,mDAAA;EACA,kCAAA;EACA,SAAA;EACA,+BAAA;EACA,mDAAA;EACA,gCAAA;EACA,0BAAA;EACA,2BAAA;EACA,4BAAA;EACA,SAAA;AACF;;AAEA;EACE,sBAAA;EACA,uBAAA;EACA,kBAAA;AACF;AAAE;EACE,mCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAEJ","sourcesContent":[":root {\n  --pill-bg: '#F5EFFF';\n  --pill-color: '#684B9B';\n  --pill-border: 'unset';\n}\n\n.primary {\n  display: flex;\n  padding: 2px 12px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 20px;\n  background: var(--pill-bg) !important;\n  color: var(--pill-color) !important;\n  text-align: right;\n  font-family: Inter;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 19.2px;\n  border: var(--pill-border) !important;\n}\n\n.inner-content-right {\n  display: flex;\n  flex-direction: row-reverse;\n}\n\n.global-search-pills {\n  padding: var(--Spacing--12px, 12px) 20px !important;\n  align-items: flex-start !important;\n  gap: 10px;\n  border-radius: 100px !important;\n  background: var(--gray-surface, #F2F2FA) !important;\n  color: var(--gray-text, #6A6D87);\n  font-size: 16px !important;\n  font-weight: 400 !important;\n  line-height: 150% !important;\n  /* 24px */\n}\n\n.autosuggest-pills {\n  width: 50px !important;\n  height: 19px !important;\n  place-self: center;\n  > span {\n    color: var(--primary-text, #684B9B);\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 160%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": `JSkafoDc_dHOYusifOxV`,
	"inner-content-right": `WH8ElQUbEFBhlCYvARpY`,
	"global-search-pills": `gtIPJiE1rwlGjfPERjjX`,
	"autosuggest-pills": `vXCNoFr2qaDnmcJheGAH`
};
export default ___CSS_LOADER_EXPORT___;
