import { Form, ListGroup, ListGroupItem, Offcanvas } from "react-bootstrap";
import styles from "./tg-selection.module.scss";
import TGIcon from "../tg-icon";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import useScreenSize from "../../utils/hook/useScreenSize";
import TGPlaceholder from "../tg-placeholder";
import { useOutsideClick } from "../../utils/hook/useClickOutside";

export interface Props {
  label: string;
  customClassName?: string;
  onChange?: (e: any, label: string) => void;
  onSelect?: (e: any, obj: any) => void;
  type?: string;
  isError?: boolean;
  errorLabel?: string;
  listData?: any;
  headerLabel?: string;
  searchPlaceHolder?: string;
  name?: string;
  disableSearch?: boolean;
  selectRadioMenu?: string;
  isCustomChild?: boolean;
  selectMenuBar?: string;
  children?: ReactNode;
  value?: string;
  forceClose?: boolean;
  id?: string;
  isLoading: boolean;
  backdropClass?: any;
  offcanvasClass?: any;
  placeholder?: string;
  displayDefaultValue?:boolean;
  defaultValue?:string;
  isEnroll?:boolean
  savedValue?:string;
  defaultObj?: any;
  isReadOnly?: boolean;
  disabled?: boolean;
  toLowerCase?: boolean;
}

const TGSelection = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const searchInput: any = useRef(null);
  const customMenuRef: any = useRef(null);
  const isMobile = useScreenSize()?.deviceSize?.width <= 768;
  const [listItem, setListItem] = useState(props.listData);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [searchText, setSearchText] = useState("");
  const[defaultValue, setDefaultValue] = useState<any>("")
  let touchTime: any = null; 
  const handleClickOutside = () => {
    if (
      (!document.activeElement?.className?.includes("offcanvas")&& isMobile) ||
      !isMobile
    ) {
      setIsOpen(false);
    }
  };
  const customRef: any = useOutsideClick(handleClickOutside);

  document.documentElement.style.setProperty(
    "--overlay-width",
    `${searchInput?.current?.offsetWidth}px`
  );

  useEffect(() => {
    setListItem(props.listData);
    if(props.savedValue){
      setSelectedValue(props.savedValue);
    }
  }, [props.listData]);

  useEffect(() => {
    if (props?.defaultObj?.image) {
      setSelectedImage(props?.defaultObj?.image);
    }
    if (props?.defaultObj?.value) {
      setSelectedValue(props?.defaultObj?.value);
    }
  }, [props?.defaultObj?.image, props?.defaultObj?.value]);

  useEffect(() => {
    if (props.savedValue) {
      setDefaultValue(props?.savedValue);
    }else{
      if(props.displayDefaultValue){
      setDefaultValue(props?.defaultValue);
    }
    if (props.isCustomChild && props.value) {
      setSelectedValue(props.value);
      setIsOpen(false);
      searchInput.current.blur();
    }
    if (props.forceClose) {
      setIsOpen(false);
    }
    if (props.value) {
      setSelectedValue(props.value);
    }
   
  }
  }, [props.isCustomChild, props.value, props.forceClose]);

  const handleSelection = (e: any, selectedObj: any) => {
    e.preventDefault();
    typeof props?.onSelect !== "undefined" && props?.onSelect(e, selectedObj);
    setSelectedValue(selectedObj.listName);
    setSelectedImage(selectedObj?.image);
    setIsOpen(false);
    setListItem(props.listData);
    searchInput.current.blur();
  };

  const handleSearchChange = (e: any, label: string) => {
    const searchVal = e.target.value;
    label === "input" && setSelectedValue("");
    setSearchText(searchVal);
    if (searchVal === "") {
      setListItem(props.listData);
      setSelectedImage("");
      return;
    }
    const filterBySearch = props.listData.filter((item: any) => {
      if (item?.listName?.toLowerCase().includes(searchVal.toLowerCase())) {
        return item;
      }
    });
    setListItem(filterBySearch);
    props?.onChange(e, label);
  };
  
  const handleClick = () => {
    if (!isMobile) {
      setIsOpen((isOpen) => !isOpen);
    }
  };

  const handleFocus = () => {
    if (isMobile) {
      setIsOpen((isOpen) => !isOpen);
      isMobile && searchInput.current.blur();
    }
  };

  const renderList = () => {
    return (
      <ListGroup variant="flush" style={{ width: "100%" }}>
        {props.isLoading || listItem === undefined ? (
          <TGPlaceholder
            typeClass="p"
            animationVariant="glow"
            variant="text"
            sizeClass={12}
            numberOfLines={6}
          />
        ) : (
          <>
            {listItem?.map((x: any, index: number) => (
              <React.Fragment key={x.id}>
                <ListGroup.Item
                  key={index}
                  className={`${styles["tg-select-list-wrapper"]} ${
                    selectedValue === x.listName &&
                    styles["tg-select-list-wrapper-selected"]
                  }`}
                  id={props.name}
                  onClick={(e: any) =>
                    !x.subItem && handleSelection(e, x)
                  }
                >
                  {x.src && (
                    <img
                      src={x.src || ""}
                      className={styles["tg-select-list-image"]}
                      alt="list"
                    />
                  )}
                  <div
                    className={styles["tg-select-list-item"]}
                    id={props.name}
                  >
                    <div className="d-flex justify-content-between">
                      <div>
                        {props?.toLowerCase
                          ? x.listName
                          : x.listName.toUpperCase()}
                      </div>
                      {selectedValue === x.listName && (
                        <TGIcon icon="check-circled" fillColor="" size="24px" />
                      )}
                    </div>
                    <ListGroup variant="flush">
                      {x.subItem?.map((y: any, index: number) => (
                        <React.Fragment>
                          <ListGroup.Item
                            key={index}
                            className={`${styles["tg-select-list-wrapper"]} ${
                              selectedValue === y.listName &&
                              styles["tg-select-list-wrapper-selected"]
                            }`}
                            id={props.name}
                            onTouchEnd={(e: any) =>
                              isMobile && handleSelection(e, y)
                            }
                            onMouseDown={(e: any) =>
                              !isMobile && handleSelection(e, y)
                            }
                            style={{ width: "100%", borderBottom: "none" }}
                          >
                            <div
                              className={styles["tg-select-list-item"]}
                              id={props.name}
                            >
                              {y.listName?.toUpperCase()}
                            </div>
                            {selectedValue === y.listName && (
                              <TGIcon
                                icon="check-circled"
                                fillColor=""
                                size="24px"
                              />
                            )}
                          </ListGroup.Item>
                        </React.Fragment>
                      ))}
                    </ListGroup>
                  </div>
                </ListGroup.Item>
              </React.Fragment>
            ))}
          </>
        )}
      </ListGroup>
    );
  };
  const renderEnrollMenu = ()=>{
    return (
      <div
        className={`${styles["tg-select-menubar"]} ${props.selectMenuBar}`}
        ref={customMenuRef}
      >
        {!props.isCustomChild ? renderList() : props?.children}
      </div>
    );
  }
  const renderMenu = () => {
    if (isMobile && isOpen) {
      return (
        <Offcanvas
          show={isOpen}
          onHide={() => setIsOpen(false)}
          placement="bottom"
          className={`${styles["tg-select-offcanvas"]} ${props.offcanvasClass}`}
          backdropClassName={props.backdropClass}
        >
          <Offcanvas.Header
            closeButton
            className={styles["tg-select-offcanvas-header"]}
          >
            <Offcanvas.Title className={styles["tg-select-offcanvas-title"]}>
              {props.headerLabel}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {!props.disableSearch && (
              <div className={styles["tg-select-search-container"]}>
                <div className={styles["tg-select-search-icon"]}>
                  <TGIcon icon="search-icon" fillColor="" size="24px" />
                </div>

                <Form.Control
                  className={`${styles["tg-select-search-box"]} ${
                    props.isError && styles["tg-select-error"]
                  }`}
                  type={props.type || "text"}
                  onChange={(e) => handleSearchChange(e, "search")}
                  placeholder={props.searchPlaceHolder}
                  value={searchText?.toUpperCase()}
                  autoComplete="off"
                />
                {searchText.length > 0 && (
                  <div
                    className={styles["tg-select-close-icon"]}
                    onClick={() => {
                      setSearchText("");
                      setListItem(props.listData);
                    }}
                  >
                    <TGIcon icon="close-icon" fillColor="" size="24px" />
                  </div>
                )}
              </div>
            )}
            {!props.isCustomChild ? renderList() : props?.children}
          </Offcanvas.Body>
        </Offcanvas>
      );
    } else if (!isMobile && isOpen) {
      return (
        <div
          className={`${styles["tg-select-menubar"]} ${props.selectMenuBar} list-menu-wrapper`}
          ref={customMenuRef}
        >
          {!props.isCustomChild ? renderList() : props?.children}
        </div>
      );
    }
  };
  return (
    <Form.Group
      className={props.customClassName}
      controlId={props.id || "exampleForm.ControlInput1"}
      ref={customRef}
    >
      <Form.Label
        className={
          !props.isEnroll
            ? styles["tg-select-label"]
            : styles["tg-enroll-input-label"]
        }
      >
        {props.label}
      </Form.Label>
      <div>
        {selectedImage && (
          <img
            src={selectedImage}
            alt={
              (props?.toLowerCase
                ? selectedValue
                : selectedValue?.toUpperCase()) ||
              searchText?.toUpperCase() ||
              defaultValue?.toUpperCase()
            }
            height="16px"
            width="24px"
            className={styles["tg-select-selected-image"]}
          />
        )}
        <Form.Control
          className={`form-select ${
            !props.isError
              ? styles["tg-select-box"]
              : styles["tg-select-control-error"]
          } ${props.isEnroll && styles["field-width"]} ${
            props.isError && "is-invalid"
          } ${selectedImage && styles["tg-select-image"]} ${
            isOpen ? styles["open-dropdown"]: ""
          }`}
          onChange={(e) => handleSearchChange(e, "input")}
          type={props.type || "text"}
          ref={searchInput}
          placeholder={props.placeholder || ""}
          value={
            (props?.toLowerCase
              ? selectedValue
              : selectedValue?.toUpperCase()) ||
            searchText?.toUpperCase() ||
            defaultValue?.toUpperCase()
          }
          name={props.name}
          readOnly={props.isReadOnly}
          onClick={handleClick}
          onFocus={handleFocus}
          autoComplete="off"
          disabled={props.disabled}
        ></Form.Control>
      </div>
      {props.isError && !isOpen && (
        <Form.Label className={styles["tg-select-error-label"]}>
          {props.errorLabel}
        </Form.Label>
      )}

      {
        props.isEnroll ? (
          isOpen && props.isEnroll && renderEnrollMenu()
        ):(
          isOpen  && renderMenu()
        )
      }
     
      
    </Form.Group>
  );
};

export default TGSelection;
