// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YZcZaNuj_8iPuC2xK8Di {
  background-color: #f9f9fc;
  min-height: 48px;
}

.dtiLgBSPYfDTLVlf74iV {
  justify-content: flex-start !important;
}

.MJm4MkaMgQBUnZ3_6Ht1 {
  color: #381b6b;
  line-height: 160%;
  font-style: normal;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/shared/tg-navbar/tg-navbar.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,gBAAA;AACF;;AAEA;EACE,sCAAA;AACF;;AAEA;EACE,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;AACF","sourcesContent":[".customNavbarClass {\n  background-color: #f9f9fc;\n  min-height: 48px;\n}\n\n.customContainerClass {\n  justify-content: flex-start !important;\n}\n\n.customLabelClass {\n  color: #381b6b;\n  line-height: 160%;\n  font-style: normal;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customNavbarClass": `YZcZaNuj_8iPuC2xK8Di`,
	"customContainerClass": `dtiLgBSPYfDTLVlf74iV`,
	"customLabelClass": `MJm4MkaMgQBUnZ3_6Ht1`
};
export default ___CSS_LOADER_EXPORT___;
