import {
  FloatingLabel,
  Form,
  ListGroup,
  ListGroupItem,
  Offcanvas,
} from "react-bootstrap";
import styles from "./tg-select.module.scss";
import TGIcon from "../tg-icon";
import { ReactNode, useEffect, useRef, useState } from "react";
import useScreenSize from "../../utils/hook/useScreenSize";
import TGPlaceholder from "../tg-placeholder";
import { useOutsideClick } from "../../utils/hook/useClickOutside";

export interface Props {
  label: string;
  customClassName?: string;
  onChange?: (e: any, label: string) => void;
  onSelect?: (e: any, obj: any) => void;
  type?: string;
  isError?: boolean;
  errorLabel?: string;
  listData?: any;
  headerLabel?: string;
  searchPlaceHolder?: string;
  name?: string;
  disableSearch?: boolean;
  selectRadioMenu?: string;
  isCustomChild?: boolean;
  selectMenuBar?: string;
  children?: ReactNode;
  value?: string;
  forceClose?: boolean;
  id?: string;
  isLoading: boolean;
  backdropClass?: any;
  offcanvasClass?: any;
  placeholder?: string;
  isFloatingLabel?: boolean;
  isReadOnly?: boolean;
  defaultValue?: any;
  focused?: any;
}

const TGFloatingSelect = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const searchInput: any = useRef(null);
  const customMenuRef: any = useRef(null);
  const isMobile = useScreenSize()?.deviceSize?.width <= 768;
  const [listItem, setListItem] = useState(props.listData);
  const [selectedValue, setSelectedValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const handleClickOutside = () => {
    if (document.activeElement?.id !== "passenger") {
      !isMobile &&
        // document.documentElement.style.setProperty("--everymundo-z-index", "1");
      !isMobile && setIsOpen(false);
    }
  };
  const customRef: any = useOutsideClick(handleClickOutside);

  document.documentElement.style.setProperty(
    "--overlay-width",
    `${searchInput?.current?.offsetWidth}px`
  );

  useEffect(() => {
    setListItem(props.listData);
  }, [props.listData]);

  useEffect(() => {
    if (props.defaultValue) {
      setSelectedValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  useEffect(() => {
    if (props?.focused?.pax) {
      searchInput?.current?.focus();
      setIsOpen(true);
      // !isMobile &&
      //   document.documentElement.style.setProperty(
      //     "--everymundo-z-index",
      //     "-1"
      //   );
    }
  }, [props?.focused?.pax]);

  useEffect(() => {
    if (props.isCustomChild && props.value) {
      setSelectedValue(props.value);
      setIsOpen(false);
      // !isMobile &&
      //   document.documentElement.style.setProperty("--everymundo-z-index", "1");

      searchInput.current.blur();
    }
    if (props.forceClose) {
      setIsOpen(false);
      // !isMobile &&
      //   document.documentElement.style.setProperty("--everymundo-z-index", "1");
    }
    if (props.value) {
      setSelectedValue(props.value);
    }
  }, [props.isCustomChild, props.value, props.forceClose]);

  const handleSelection = (e: any, selectedObj: any) => {
    e.preventDefault();
    typeof props?.onSelect !== "undefined" && props?.onSelect(e, selectedObj);
    setSelectedValue(selectedObj.listName);
    setIsOpen(false);
    !isMobile &&
      document.documentElement.style.setProperty("--everymundo-z-index", "1");
    setListItem(props.listData);
    searchInput.current.blur();
  };

  const handleSearchChange = (e: any, label: string) => {
    const searchVal = e.target.value;
    label === "input" && setSelectedValue("");
    setSearchText(searchVal);
    if (searchVal === "") {
      setListItem(props.listData);
      return;
    }
    const filterBySearch = props.listData.filter((item: any) => {
      if (item?.listName?.toLowerCase().includes(searchVal.toLowerCase())) {
        return item;
      }
    });
    setListItem(filterBySearch);
    props.onChange && props.onChange(e, label);
  };

  const handleClick = () => {
    if (!isMobile) {
      setIsOpen((isOpen) => !isOpen);
    }
  };

  const handleFocus = () => {
    if (isMobile) {
      setIsOpen((isOpen) => !isOpen);
      isMobile && searchInput.current.blur();
    }
  };

  const renderList = () => {
    return (
      <ListGroup variant="flush" style={{ width: "100%" }}>
        {props.isLoading || listItem === undefined ? (
          <TGPlaceholder
            typeClass="p"
            animationVariant="glow"
            variant="text"
            sizeClass={12}
            numberOfLines={6}
          />
        ) : (
          <>
            {listItem?.map((x: any, index: number) => (
              <ListGroup.Item
                key={index}
                className={`${styles["tg-select-list-wrapper"]} ${
                  selectedValue === x.listName &&
                  styles["tg-select-list-wrapper-selected"]
                }`}
                id={props.name}
                onTouchEnd={(e: any) => isMobile && handleSelection(e, x)}
                onMouseDown={(e: any) => !isMobile && handleSelection(e, x)}
              >
                {x.src && (
                  <img
                    src={x.src || ""}
                    className={styles["tg-select-list-image"]}
                    alt="list"
                  />
                )}
                <div className={styles["tg-select-list-item"]} id={props.name}>
                  {x.listName}
                </div>
                {selectedValue === x.listName && (
                  <TGIcon icon="check-circled" fillColor="" size="24px" />
                )}
              </ListGroup.Item>
            ))}
          </>
        )}
      </ListGroup>
    );
  };

  const renderMenu = () => {
    if (isMobile && isOpen) {
      return (
        <Offcanvas
          show={isOpen}
          onHide={() => setIsOpen(false)}
          placement="bottom"
          className={`${styles["tg-select-offcanvas"]} ${props.offcanvasClass}`}
          backdropClassName={props.backdropClass}
        >
          <Offcanvas.Header
            closeButton
            className={styles["tg-select-offcanvas-header"]}
          >
            <Offcanvas.Title className={styles["tg-select-offcanvas-title"]}>
              {props.headerLabel}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {!props.disableSearch && (
              <div className={styles["tg-select-search-container"]}>
                <div className={styles["tg-select-search-icon"]}>
                  <TGIcon icon="search-icon" fillColor="" size="24px" />
                </div>

                <Form.Control
                  className={`${styles["tg-select-search-box"]} ${
                    props.isError && styles["tg-select-error"]
                  }`}
                  type={props.type || "text"}
                  onChange={(e) => handleSearchChange(e, "search")}
                  placeholder={props.searchPlaceHolder}
                  value={searchText}
                  autoComplete="off"
                />
                {searchText.length > 0 && (
                  <div
                    className={styles["tg-select-close-icon"]}
                    onClick={() => {
                      setSearchText("");
                      setListItem(props.listData);
                    }}
                  >
                    <TGIcon icon="close-icon" fillColor="" size="24px" />
                  </div>
                )}
              </div>
            )}
            {!props.isCustomChild ? renderList() : props?.children}
          </Offcanvas.Body>
        </Offcanvas>
      );
    } else if (!isMobile && isOpen) {
      return (
        <div
          className={`${styles["tg-select-menubar"]} ${props.selectMenuBar} booking-widget-overlay`}
          ref={customMenuRef}
        >
          {!props.isCustomChild ? renderList() : props?.children}
        </div>
      );
    }
  };
  return (
    <Form.Group
      className={props.customClassName}
      controlId={props.id || "exampleForm.ControlInput1"}
      ref={customRef}
    >
      {props.isError && (
        <span className={styles["tg-select-error-icon"]}>
          <TGIcon icon="error-icon" fillColor="" size="16" />
        </span>
      )}
      <FloatingLabel
        controlId="floatingSelect"
        label={props.label}
        className={
          isOpen || selectedValue
            ? styles["tg-floating-select-label"]
            : styles["tg-select-label"]
        }
      />
      <div className="d-flex flex-row">
        <Form.Control
          className={`form-select ${styles["tg-select-box"]} ${
            props.isError && styles["tg-select-error"]
          } ${
            (isOpen || selectedValue) &&
            props.label.trim() !== "" &&
            styles["tg-floating-select-input"]
          }`}
          onChange={(e) => handleSearchChange(e, "input")}
          readOnly={props.isReadOnly}
          type={props.type || "text"}
          ref={searchInput}
          id="passenger"
          placeholder={props.placeholder || ""}
          value={selectedValue || searchText}
          name={props.name}
          onClick={handleClick}
          onFocus={handleFocus}
          autoComplete="off"
        />
      </div>
      {props.isError && !isOpen && (
        <Form.Label className={styles["tg-select-error-label"]}>
          {props.errorLabel}
        </Form.Label>
      )}
      {isOpen && renderMenu()}
    </Form.Group>
  );
};

export default TGFloatingSelect;
